import { useEffect, useRef, useState } from "react";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

const YoutubePlayer = ({ videoId }) => {
  const [videoUrl, setVideoUrl] = useState(
    `https://www.youtube.com/watch?v=${videoId}`
  );
  const [embedHtml, setEmbedHtml] = useState("");
  const playerRef = useRef(null);
  const [isStarting, setIsStarting] = useState(false);

  useEffect(() => {
    const fetchEmbedData = async () => {
      if (videoUrl) {
        try {
          const response = await fetch(
            `https://noembed.com/embed?url=${videoUrl}`
          );
          const data = await response.json();
          console.log(data);
          // Extract the iframe HTML
          const modifiedHtml = data.html
            .replace(
              'src="https://www.youtube.com/embed/',
              'src="https://www.youtube-nocookie.com/embed/'
            )
            .replace(
              "feature=oembed",
              "feature=oembed&autoplay=0&mute=1&loop=1&color=white&controls=1&modestbranding=1&playsinline=1&rel=0&enablejsapi=1"
            )
            .replace(
              "<iframe ",
              '<iframe playsinline="1" ' // Adds playsinline attribute directly to iframe
            );

          setEmbedHtml(modifiedHtml);
          const searchForSpritePlyr = setInterval(() => {
            const spriteDiv = document.getElementById("sprite-plyr");
            if (spriteDiv) {
              console.log("Found sprite-plyr!");

              // Stop searching once the div is found
              clearInterval(searchForSpritePlyr);

              // Now search for the div with class "plyr__video-wrapper plyr__video-embed"
              const videoWrapperDiv = document.querySelector(
                ".plyr__video-wrapper.plyr__video-embed"
              );
              if (videoWrapperDiv) {
                console.log("Found video wrapper!");
                // Set its display to block
                setTimeout(() => {
                  videoWrapperDiv.style.display = "block";
                }, 500);
              }
            }
          }, 200);
        } catch (error) {
          console.error("Error fetching video data:", error);
        }
      }
    };

    fetchEmbedData();
  }, [videoUrl]);

  useEffect(() => {
    if (embedHtml && playerRef.current) {
      // Create a new Plyr instance with custom controls
      const player = new Plyr(playerRef.current, {
        controls: [
          "rewind",
          "play",
          "fast-forward",
          "progress",
          "current-time",
          "mute",
          "volume",
          "settings",
        ],
        autoplay: false,
        quality: {
          default: 720,
          options: [144, 240, 360, 480, 720, 1080],
        },
      });
      player.on("play", () => {
        console.log("Video started playing");
        setIsStarting(true);
      });

      return () => {
        player.destroy();
      };
    }
  }, [embedHtml]);

  useEffect(() => {
    if (isStarting) {
      const timeoutId = setTimeout(() => {
        setIsStarting(false);
      }, 2000);
    }
    console.log(isStarting, "isStarting");
  }, [isStarting]);

  return (
    <div style={{ width: "75%", position: "relative", margin: "0 auto" }}>
      {embedHtml && (
        <div
          className="plyr__video-embed hytPlayerWrapOuter"
          ref={playerRef}
          dangerouslySetInnerHTML={{ __html: embedHtml }}
        />
      )}
      {isStarting && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "3rem", // Adjust size as needed
            color: "#fff", // Adjust color as needed
            textAlign: "center",
          }}
        >
          <span>🎬</span>
        </div>
      )}
    </div>
  );
};

export default YoutubePlayer;
