import { createBrowserRouter } from "react-router-dom";
import Login from "@/modules/auth/pages/Login";
import Error from "./Error";
import SideNav from "@/layout/adminDashboard/SideNav";
import CoursesHomePage from "@/modules/courses/pages/CoursesHomePage";
import StudentsHomePage from "@/modules/students/pages/StudentsHomePage";
import { BookStorePage } from "@/modules/bookStore/pages/BookStorePage";
import CoursesCodesPage from "@/modules/codes/pages/CoursesCodesPage";
import NotificationsPage from "@/modules/notification/pages/NotificationsPage";
import SupervisorsPage from "@/modules/supervisor/pages/SupervisorsPage";
import { Home } from "@/modules/home/pages/Home";
import { DashboardHome } from "@/modules/DahsboardHome/pages/DashboardHome";
import CourseDetails from "@/modules/codes/pages/CourseDetails";
import PrintCodes from "@/modules/codes/pages/printCodes";
import { UserHome } from "@/modules/user/UserHome/UserHome";
import { Main } from "@/modules/user/Main/Main";
import { UserCourse } from "@/modules/user/UserCourse/UserCourse";
import { MySubscriptions } from "@/modules/user/MySubscriptions/MySubscriptions";
import { AllCourses } from "@/modules/user/AllCourses/AllCourses";
import { Module } from "@/modules/user/Module/Module";
import { Course } from "@/modules/courses/pages/Course";
import BookOrders from "@/modules/bookStore/pages/BookOrders";
import { CourseOverallExam } from "@/modules/user/CourseOverallExam/CourseOverallExam";
import MyStats from "@/modules/user/MyStats/MyStats";
import { MyDetails } from "@/modules/user/MyDetails/MyDetails";
import { UserBookStore } from "@/modules/user/UserBookStore/UserBookStore";
import { UserBookOrders } from "@/modules/user/UserBookOrders/UserBookOrders";
import { features } from "./features";
import DeleteAccountFake from "@/modules/user/DeleteAccountFake";
import PrivacyPolicy from "@/modules/user/Privacy";
import Signup from "@/modules/auth/pages/Signup";
import TermsOfUsePage from "@/modules/user/TermsOfUserPage";
import Settings from "@/modules/settings/pages/Settings";
import MobileView from "@/modules/mobileView/MobileView";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: "/mobileView/:id",
    element: <MobileView />,
    errorElement: <Error />,
  },
  { path: "privacy", element: <PrivacyPolicy />, errorElement: <Error /> },
  {
    path: "user-profile",
    element: <DeleteAccountFake />,
    errorElement: <Error />,
  },
  {
    path: "terms-of-user-page",
    element: <TermsOfUsePage />,
    errorElement: <Error />,
  },
  {
    path: "/dashboard",
    element: <SideNav />,
    errorElement: <Error />,
    children: [
      { path: "", element: <DashboardHome /> },
      { path: "courses", element: <CoursesHomePage /> },
      { path: "courses/course/:id", element: <Course /> },
      { path: "students", element: <StudentsHomePage /> },
      { path: "book-store", element: <BookStorePage /> },
      { path: "book-store/order/:id", element: <BookOrders /> },
      { path: "coursesCodes", element: <CoursesCodesPage /> },
      { path: "coursesCodes/course/:id", element: <CourseDetails /> },
      { path: "notifications", element: <NotificationsPage /> },
      { path: "settings", element: <Settings /> },
      { path: "supervisors", element: <SupervisorsPage /> },
      {
        path: "coursesCodes/course/:id/printCodes",
        element: <PrintCodes />,
      },
    ],
  },
  ...(() => {
    if (features.student) {
      return [
        {
          path: "/home",
          element: <UserHome child={Main} />,
          errorElement: <Error />,
        },
        {
          path: "/signup",
          element: <Signup />,
          errorElement: <Error />,
        },
        {
          path: "/my-subscriptions",
          element: <UserHome child={MySubscriptions} />,
          errorElement: <Error />,
        },
        {
          path: "/all-courses",
          element: <UserHome child={AllCourses} />,
          errorElement: <Error />,
        },
        {
          path: "/my-stats",
          element: <UserHome child={MyStats} />,
          errorElement: <Error />,
        },
        {
          path: "/my-details",
          element: <UserHome child={MyDetails} />,
          errorElement: <Error />,
        },
        {
          path: "/my-orders",
          element: <UserHome child={UserBookOrders} />,
          errorElement: <Error />,
        },
        {
          path: "/book-store",
          element: <UserHome child={UserBookStore} />,
          errorElement: <Error />,
        },
        {
          path: "/course/:id",
          element: <UserHome child={UserCourse} />,
          errorElement: <Error />,
        },
        {
          path: "/course/:id/course-taps/:mid",
          element: <UserHome child={Module} />,
          errorElement: <Error />,
        },
        {
          path: "/course/:id/course-overall-exam/:eid",
          element: <UserHome child={CourseOverallExam} />,
          errorElement: <Error />,
        },
      ];
    } else {
      return [];
    }
  })(),
]);
