import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { ClipLoader } from "react-spinners";
import YoutubePlayer from "./YoutubePlayer";
import { api_url } from "@/constants/base_url";
const MobileView = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const token = searchParams.get("token");
  const expires = searchParams.get("expires");
  const module_id = searchParams.get("module_id");
  const user_id = searchParams.get("user_id");
  const signature = searchParams.get("signature");

  console.log(searchParams, "searchParams");
  const { data, error, isLoading } = useQuery({
    queryKey: ["mobileView", id, token],
    queryFn: () =>
      axios.post(
        `${api_url}lesson?lesson_id=${id}&expires=${expires}&module_id=${module_id}&user_id=${user_id}&signature=${signature}`,
        {
           lesson_id: +id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  function extractYouTubeVideoId(url) {
    const patterns = [
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"'>\n\s?&]+)/,
    ];

    for (let pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null; // return null if no match found
  }
  if (error) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          gap: 10,
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: "#262624",
          fontFamily: "Noor-bold",
          fontSize: 28,
        }}
      >
       {/* <MdError 
          style={{ fontSize: 40, color: "#e93939" }}
        /> */}
        {(() => {
          switch (error.response.status) {
            case 401:
              return <div>يجب عليك تسجيل الدخول اولا</div>;
            case 403:
              return <div>انت لست مشترك</div>;
            case 404:
              return <div>هذا المحتوي غير متوفر</div>;
            case 500:
              return <div>حدث خطأ ما برجاء المحاولة في وقت لاحق</div>;
            default:
              return <div>حدث خطأ ما برجاء المحاولة في وقت لاحق</div>;
          }
        })()}
      </div>
    );
  }
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          gap: 10,
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: "#262624",
          fontFamily: "Noor-bold",
          fontSize: 28,
        }}
      >
        <ClipLoader color="#fff" size={20} />
      </div>
    );
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontFamily: "Noor-bold",
        fontSize: 28,
        backgroundColor: "#262624",
      }}
    >
      <YoutubePlayer videoId={extractYouTubeVideoId(data?.data?.data?.link)} />
      {/* <YoutubePlayer videoId={"XnJcUnxn0qI"}/> */}
    </div>
  );
};

export default MobileView;
